import React, { useState } from 'react'
import { connect } from 'react-redux'
import { navigate, Link } from 'gatsby'
import styled, { css } from 'styled-components'

import { media, useBreakpoint, isClient } from '../../styles/utils'
import { getSettings, fetchSettings, updateSettings } from '../../store/settings'
import { container, bgIcon, padding, hoverState, bgImage } from '../../styles/global'
import { maroon } from '../../styles/colors';
import { slugify } from '../../utils';
import { useLocation } from 'react-use';

const footerItems = [
	{
		content: '275-285 EXHIBITION ST, MELBOURNE 3000',
	},
	{
		content: '0396637994',
		type: 'phone'
	},
	{
		content: 'RESERVATIONS@PEPESITALIAN.COM.AU',
		type: 'email'
	},
	{
		content: 'EVENTS@PEPESITALIAN.COM.AU',
		type: 'email'
	},
	{
		content: 'TUESDAY TO SUNDAY, 12PM TIL LATE',
	}
]

export const FooterMobile = (props) => {
	return (
		<ContainerMobile>
			{footerItems.map((item, i) => {
				return (
					<MenuItemWrapper
						key={i}
					>
						{
							!!item.type ? (
								<a href={`${item.type === 'phone' ? 'tel:+' : 'mailto:'}${item.content}`}>
									<Heading>
										{item.content}
									</Heading>
								</a>
							) : (
								<Heading>
									{item.content}
								</Heading>
							)
						}
					</MenuItemWrapper>
				)
			})}
		</ContainerMobile>
	)
}

const Footer = (props) => {
	return (
		<Wrapper>
			<Container>
				{footerItems.map((item, i) => {
					return (
						<MenuItemWrapper
							key={i}
						>
							{
								!!item.type ? (
									<a href={`${item.type === 'phone' ? 'tel:+' : 'mailto:'}${item.content}`}>
										<Heading>
											{item.content}
										</Heading>
									</a>
								) : (
									<Heading>
										{item.content}
									</Heading>
								)
							}
						</MenuItemWrapper>
					)
				})}
			</Container>
		</Wrapper>
	)
}

// Shared

const Heading = styled.div``

// Layout (Desktop)

const Wrapper = styled.div`
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	height: 40px;
	background: ${maroon};
	z-index: 1;

	display: flex;
	justify-content: center;

	${media.tablet`
		display: none;
	`}
`

const Container = styled.div`
	${container}
	justify-content: space-between;
	margin-left: 50px;
	margin-right: 50px;
`

const MenuItemWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding-bottom: 4px;
	/* flex: 1; */
	position: relative;
	cursor: pointer;

	/* > ${MenuItem} {
		flex: 1;
	} */

	${Heading} {
		font-size: 12px;
		color: white;
		/* font-weight: bold; */
		text-transform: uppercase;
		/* border-bottom: 2px solid transparent;
		transform: translateY(2px); */

		@media (max-width: 1440px) {
			font-size: 12px;
		}

		@media (max-width: 1210px) {
			font-size: 12px;
		}
	}
`

const ContainerMobile = styled.div`
	height: unset;
	/* width: 100%; */
	border-top: 1px solid white;
	justify-content: space-between;
	flex-direction: column;
	margin-left: 50px;
	margin-right: 50px;
	padding-top: 20px;
	margin-top: 20px;

	${MenuItemWrapper} {
		margin-bottom: 10px;
	}

	${Heading} {
		text-align: center;
	}
`


export default Footer
