import React, { Component } from 'react'
import Helmet from 'react-helmet'

class MetaTags extends Component {

    static defaultProps = {
        title: "Pepe's Italian & Liquor - 275-285 Exhibition St Melbourne",
        keywords: [
            'Italian Restaurant near me',
            'Bar near me',
            'Pasta restaurant near me',
            'Restaurant near me',
            'Italian Melbourne',
        ]
    }

    render() {

        const {
            title,
            keywords,
            description,
            opengraph_description,
            opengraph_image,
            opengraph_title,
            twitter_description,
            twitter_image,
            twitter_title,
        } = this.props;

        return (
            <Helmet
                title={title}
                meta={[
                    { name: 'viewport', content: 'width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1.0, user-scalable=0' },
                    { name: 'description', content: description },
                    { property: 'og:image', content: opengraph_image },
                    { property: 'og:title', content: opengraph_title ? opengraph_title : title },
                    { property: 'og:description', content: opengraph_description ? opengraph_description : description },
                    { property: 'twitter:card', content: 'summary' },
                    { property: 'twitter:image', content: twitter_image ? twitter_image : opengraph_image },
                    { property: 'twitter:title', content: twitter_title ? twitter_title : title },
                    { property: 'twitter:description', content: twitter_description ? twitter_description : description },
                    { name: 'keywords', content: keywords.join(', ') },
                ]}
            />
        )
    }
}

export default MetaTags