import React, { useState, useRef } from 'react'
import { connect } from 'react-redux'
import { navigate, Link } from 'gatsby'
import styled, { css, keyframes } from 'styled-components'
import Form from 'react-dynamic-form'
import { useSetState } from 'react-use';

import { Input } from '../'

import { postData } from '../../services/api';
import { media } from '../../styles/utils'
import { formData } from '../../data/enquire'
import { transformEnquireData } from './utils'
import { getSettings, fetchSettings, updateSettings } from '../../store/settings'
import { container, bgIcon, padding, hoverState, bgImage, button, maxWidth } from '../../styles/global'
import { maroon } from '../../styles/colors';

const EnquireForm = (props) => {	
	const form = useRef(null);

	const [formState, updateForm] = useSetState({
		submitting: false, 
		formComplete: false,
		error: false,
	})

	const handleSubmit = (fields, resetForm) => {
		const data = transformEnquireData(fields)

		postData('/pda/enquiryform', data)
			.then(res => {
           
				if (res.success) {
					updateForm({
						error: null,
						submitting: false,
						formComplete: true,
					})
				} else {
					updateForm({
						error: res.message,
						submitting: false
					})
				}
				
				resetForm()
			})
			.catch(error =>  {
				updateForm({
					error: 'Server Error',
					submitting: false
				})

				resetForm()
			})  
	}

	const renderFormSection = (section, fields, index) => {
		return (
			<Section
				key={index}
			>
				{section.heading && (
					<Heading>{section.heading}</Heading>
				)}

				{section.description && (
					<Description>{section.description}</Description>
				)}

				{fields}
			</Section>
		)
	}

	const renderForm = () => {
		const { error, submitting, formComplete } = formState;
		
		return (
			<FormWrapper>
				<Form
					ref={form}
					data={formData}
					useSections={true}
					renderSection={renderFormSection}
					renderInput={<Input/>}
					moveToInvalidField={true}
					styles={formStyles}
					renderSubmit={false}
					onSubmit={handleSubmit}
				/>

				{error && (
					<Error>{error}</Error>
				)}

				<Button
					submitting={submitting}
					onClick={() => {
						form.current && form.current.handleSubmit()
					}}
				>
					{submitting ? <Loading/> : 'Submit Enquiry'}
				</Button>
			</FormWrapper>
		)
	}

	const renderThankYou = () => {
		const { error, submitting, formComplete } = formState;
		
		return (
			<ThankYou>
				Thanks for your enquiry!
			</ThankYou>
		)
	}

	return (
		<Wrapper
			ref={props.ref}
			id={'enquire'}
		>
			<Container>
				<Heading>Enquiries</Heading>
				{formState.formComplete ? renderThankYou() : renderForm()}
			</Container>
		</Wrapper>	
	)
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``
const Info = styled.div``

const Button = styled.div`
	${button}

	${props => {
		if (props.submitting) return css`
			background: ${maroon};
		`
	}}

	${media.phone`
		max-width: none;
	`}
`

// Layout

const Wrapper = styled.div`
	background: #FEFBEF;
	position: relative;

	display: flex;
	flex-direction: column:
	align-items: center;
	justify-content: center;
	width: 100vw;
	${padding}
	margin-top: 10px;

`

const Container = styled.div`
	${container}
	width: 100%;
	max-width: 940px;
	padding-bottom: 96px;

	display: flex;
	flex-direction: column;
	
	${Heading} {
		font-family: 'learning-curve';
		font-size: 55px;
		margin: 48px 0 40px;
		align-self: center;
		font-weight: 600;

		${media.tablet`
			font-size: 32px;
		`}
	}

	${media.phone`
		padding-bottom: 39px;
	`}
`

// Form


const FormWrapper = styled.div`
	display: flex;	
	flex-direction: column;	
	width: 100%;

	${Button} {
		align-self: center;
		margin-top: 24px;
	}	

	${media.phone`
		max-width: none;
	`}
`

const Section = styled.div`
	width: 100%;
	margin-bottom: 48px;	

	${media.phone`
		margin-bottom: 0;
	`}
`

const formStyles = css`
	flex-direction: column;
	align-items: flex-start;
    display: flex;	
	position: relative;
	
	/* Field overrides */

	.dynamic-fields {
		width: 100%;
		justify-content: space-between;
		
		&:not(:first-child) {
			margin-top: 15px;
		}
	}

	.dynamic-field {
		margin-bottom: 20px;

		&.phone,
		&.email,
		&.date,
		&.functionType,
		&.style,
		&.requiredCapacity {
			flex-basis: calc(50% - 14px);
		}

		${media.phone`
			flex-basis: 100% !important;
		`}
	}
`

const Error = styled.div`
	font-size: 16px;
	margin-top: 12px;
	color: red;
	text-transform: uppercase;
	align-self: center;
	font-weight: bold;
`

const ThankYou = styled(Error)`
	color: black;
	margin: 60px 0 40px;
	font-size: 19px;
`

const rotate360 = keyframes`
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
`

const Loading = styled.div`
	width: 20px;
	height: 20px;
    background-image: url(${require('../../assets/icons/loading.svg')});
	${bgIcon}
	animation: ${rotate360} 1s linear infinite;
`


export default EnquireForm
