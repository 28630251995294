import React, {Component} from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'

import { media } from '../../styles/utils'
import { bgIcon, bgImage } from '../../styles/global'

class Checkbox extends Component {

    toggle = () => {
        const { onChange, value, defaultValue } = this.props;
        onChange(value !== null ? !value : !defaultValue, true);
    }

    render() {
        const { value, label, defaultValue } = this.props;
        return (
            <Wrapper>
                <Container>
                    <Box
                        active={value !== null ? value : defaultValue}
                        onClick={this.toggle}
                    />
                    <Label
                        className={'label'}
                        onClick={this.toggle}
                    >   
                        {label}
                    </Label>
                </Container>
            </Wrapper>
        )
    }
}


export const Wrapper = styled.div`
    position: relative;
    width: 100%;
    margin-left: auto;
    user-select: none;
`

const Container = styled.div`
    display: flex;
    align-items: flex-start;
`

const Label = styled.div`
    transform: translateY(5px);
    cursor: pointer;

    font-size: 16px;
    color: black;
    text-transform: uppercase;
    font-weight: bold;

    ${media.phone`
        font-size: 12px;
    `}
`

const Box = styled.div`
    flex: 1 0 32px;
    max-width: 32px;
    width: 32px;
    height: 32px;
    border: 2px solid black;
    position: relative;
    margin-right: 17px;
    cursor: pointer;

    ${props => {
        if (props.active) return css`
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-image: url(${require('../../assets/icons/check.svg')});
                ${bgImage};
                width: 32px;
                height: 32px;

                ${media.phone`
                    width: 19px;
                    height: 19px;
                `}
            }
        `
    }}

    ${media.phone`
        flex: 1 0 19px;
        max-width: 19px;
        width: 19px;
        height: 19px;
        margin-right: 12px;
    `}
`

export default Checkbox;