import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import 'react-id-swiper/lib/styles/css/swiper.css';

import { MetaTags, Header } from '../'
import { gold } from '../../styles/colors';
import { media } from '../../styles/utils';

const Layout = ({ children, location, meta, menu, overSlider }) => {
	return (
		<>
			<MetaTags
				{...meta}
			/>

			{/* Header */}

			{menu && (
				<Header
					overSlider={overSlider}
					menu={menu}
				/>
			)}

			{/* Layout */}

			<Wrapper
				overSlider={overSlider}
				parent={menu && menu.parent}
			>
				{children}
			</Wrapper>
		</>
	)
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	min-height: calc(100vh - 50px);
	box-sizing: border-box;
	overflow-x: hidden;
	background-color: #F1BFB1;

	/* Trunk Diner Theme */

	${props => {
		if (props.parent && props.parent.slug == 'trunk-diner') return css`
			/* background: ${gold}; */
		`
	}}

	${media.tablet`
		min-height: 100vh;
		padding-top: 45px;

		${props => {
			if (props.overSlider) return css`
				padding-top: 0;
			`
		}}
	`}
`

export default Layout
