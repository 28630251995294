import React, { Component, useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import logo from '../../assets/images/pepes-logo.svg'
import { media } from '../../styles/utils'
import { bgIcon, bgImage } from '../../styles/global'
import Cookies from 'universal-cookie'
import image from '../../assets/images/chalet.gif'
import brbImage from '../../assets/images/brb.png'

const PopupChalet = () => {
  // Create a Cookie with an expiring date (+ 7 days)
  // let cookies = new Cookies();
  // const plusOneWeek = new Date();
  // plusOneWeek.setDate(plusOneWeek.getDate()+7);
  // const [cookieSet, setCookie] = useState(true);

  // // Set a cookie with there is none
  // useEffect(() => {
  //     if (cookies.get('Popup-Chalet') !== 'set') {
  //         cookies.set('Popup-Chalet', 'set', { path: '/', expires: plusOneWeek  });
  //     setCookie(true);
  //     };
  // }, []);

  const [active, setActive] = useState(
    new Date().getTime() > new Date('2023-05-15').getTime() &&
      new Date().getTime() < new Date('2023-10-02').getTime()
  )

  // Returns the popup only if a cookie has just been set
  if (active) {
    return (
      <PopupContainer className={active ? 'show-popup' : 'hide-popup'}>
        <PopupContent>
          {
            <img
              src={image}
              onClick={() => {
                setActive(false)
              }}
            />
          }
        </PopupContent>
      </PopupContainer>
    )
  } else {
    return null
  }
}

//Popup
export const PopupContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  pointer-events: none;
  z-index: 5;
  text-align: -webkit-center;
  &.hide-popup {
    visibility: hidden;
  }
`

const PopupContent = styled.div`
  img {
    width: 100%;
    cursor: pointer;
    pointer-events: all;

    &.brbimage {
      max-width: 800px !important;
    }
  }
`

const LogoPopup = styled.div`
  background-image: url(${logo});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 30%;
  margin-bottom: 20px;
`

const PopupText = styled.div`
  font-size: 2rem;
  p,
  h1 {
    font-family: 'learning-curve';
    color: white;
  }
  h1 {
    font-size: 3rem;
    margin-bottom: 10px;
    ${media.phone`
            font-size: 2.5rem;
            `}
  }
  p {
    margin-top: 20px;
    margin-bottom: 0px;
    ${media.phone`
            font-size: 1.5rem;
            line-height: 30px;
            `}
  }
  a {
    color: white;
  }
`

const PopupClosingTag = styled.div`
    color: white;
    position: absolute;
    top: 0%;
    right: 3%;
    font-size: 2rem;
    cursor: pointer;
        ${media.phone`
            margin-top: 4px;
            font-size: 1.5rem;
        `}
}
`

export default PopupChalet
