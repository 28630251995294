import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import ProgressiveImage from "react-progressive-image";
import Swiper from 'react-id-swiper';

import { media } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage, maxWidth } from '../../../styles/global'
import { isNull } from 'lodash-es';


const BlockSlider = (props) => {
	const { images, sliderStyles, hoverArrows, fullScreen } = props;
	const [swiper, updateSwiper] = useState(null);
	const canLoop = images && images.length > 1;

	const swiperParams = {
		getSwiper: updateSwiper,
		loop: canLoop && true,
		pagination: {
			el: canLoop && '.swiper-pagination',
			type: 'bullets',
		},
	}

	const renderHoverArrows = (params) => {
		const hoverSections = [
			{
				position: 'left',
				onClick: () => swiper.slidePrev()
			},
			{
				position: 'right',
				onClick: () => swiper.slideNext()
			},
		]

		return hoverSections.map((section, i) => {
			return (
				<HoverSection
					key={i}
					className={'hover-section'}
					position={section.position}
				>
					<Arrow
						className={'slider-arrow'}
						onClick={section.onClick}
					/>
				</HoverSection>
			)
		})
	}

	const renderSlides = () => {
		if (!images) {
			return null
		}

		return images.map((item, i) => {
			if (!item.image) return;

			return (
				<Slide
					key={i}
				>
					<Image
						className={'slider-image'}
						image={item.image.sizes.medium2}
					/>
				</Slide>
			)
		})
	}

	return (
		<Wrapper
			sliderStyles={sliderStyles}
			fullScreen={fullScreen}
			canLoop={canLoop}
		>
			<Swiper
				{...swiperParams}
			>
				{renderSlides()}
			</Swiper>

			{hoverArrows && canLoop && renderHoverArrows()}
		</Wrapper>
	)
}


// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``
const Info = styled.div``

const Image = styled.div`
	background-image: url(${props => props.image});  
	${bgImage}
`

// Layout

const defaultSliderStyles = css`
	max-width: 100%;
	height: 800px;
	max-height: 60vh;

	${media.tablet`
		height: 480px;
	`}

	${media.phone`
		height: 220px;
		max-height: none;
	`}
`

const Wrapper = styled.div`
	position: relative;

	.swiper-container {
		${props => props.sliderStyles || defaultSliderStyles}
	}

	/* Pagination */

	.swiper-pagination {
		bottom: 20px;

		.swiper-pagination-bullet {
			width: 7px;
			height: 7px;
			border: 2px solid white;
			opacity: 1;
			background: none;

			&.swiper-pagination-bullet-active {
				background: white;
			}

			${media.phone`
				width: 6px;
				height: 6px;
				border: 1px solid white;
			`}
		}
	}

	${props => !props.fullScreen && props.canLoop && media.phone`
		.swiper-container { 
			padding-bottom: 32px;
		}

		.swiper-pagination {
			.swiper-pagination-bullet {
				border-color: black;

				&.swiper-pagination-bullet-active {
					background: black;
				}
			}
		}
	`}
`

// Slide

const Slide = styled.div`
	${Image} {
		height: 100%;
	}
`

// Arrow Hover

const Arrow = styled.div`
	background-image: url(${require('../../../assets/icons/slider-arrow-white.svg')});	
	${bgImage};
	width: 108px;
	height: 68px;
`

const HoverSection = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	width: calc((100% - 940px) / 2);
	z-index: 2;

	display: flex;
	justify-content: center;
	align-items: center;

	${Arrow} {
		transition: opacity 0.15s ease;
		cursor: pointer;
	}

	&:hover {
		${Arrow} {
			opacity: 1;
		}
	}

	/* Positions */

	${props => {
		if (props.position == 'left') return css`
			left: 0;
		`

		if (props.position == 'right') return css`
			right: 0;

			${Arrow} {
				transform: rotate(-180deg);
			}
		`
	}}

	${media.tablet`
		display: none;
	`}
`


BlockSlider.wrapper = css`
	margin-bottom: 0;
`

export default BlockSlider
