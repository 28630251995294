import React, {Component, useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import logo from '../../assets/images/pepes-logo.svg'
import { media } from '../../styles/utils'
import { bgIcon, bgImage } from '../../styles/global'
import Cookies from 'universal-cookie';



const Popup = () => {
    // Create a Cookie with an expiring date (+ 7 days)
    let cookies = new Cookies();
	const plusOneWeek = new Date();
	plusOneWeek.setDate(plusOneWeek.getDate()+7);
    const [cookieSet, setCookie] = useState(false);

    // Set a cookie with there is none
    useEffect(() => {
        if (cookies.get('Popup') !== 'set') {
            cookies.set('Popup', 'set', { path: '/', expires: plusOneWeek  });
        setCookie(true);
        };
    }, []);

    const [active, setActive] = useState(true);

    // Returns the popup only if a cookie has just been set
    if (cookieSet === true) {
        return (
            <PopupContainer
                className={active ? 'show-popup' : 'hide-popup'}
            >
                <PopupContent>
                    <PopupClosingTag
                        onClick={() => setActive(active => !active)}
                    >
                    x</PopupClosingTag>
            
                    <LogoPopup />

                    <PopupText>
                        <h1>Takeaway</h1>
                        <a href="https://admin.pepesitalian.com.au/wp-content/uploads/2020/08/FINALFLYERPDF_removed.pdf" target="blank">CLICK HERE FOR MENU</a>
                        <p>Phone Orders only<br/>9663 7994</p>
                    </PopupText>

                </PopupContent>
            </PopupContainer>
        )
    } else {
        return (null)
    }
}



    //Popup
export const PopupContainer = styled.div`
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0%;
    background: #808080b3;
    z-index: 5;
    text-align: -webkit-center;
        &.hide-popup {
        visibility: hidden;
}
`

const PopupContent = styled.div`
    text-align: -webkit-center;
    background: #451F1F;
    z-index: 4;
    width: fit-content;
    padding: 30px;
    height: 350px;
    width: 450px;
    box-shadow: 10px 10px 16px rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
        ${media.phone`
            width: 200px;
            height: 190px;
            `}
`

const LogoPopup = styled.div`
    background-image: url(${logo});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;	
    height: 30%;
    margin-bottom: 20px;
`

const PopupText = styled.div`
    font-size: 2rem;
    p, h1 {
        font-family: 'learning-curve';
        color: white;
    }
    h1 {
        font-size: 3rem;
        margin-bottom: 10px;
        ${media.phone`
            font-size: 2.5rem;
            `}
    }
    p {
        margin-top: 20px;
        margin-bottom: 0px;
        ${media.phone`
            font-size: 1.5rem;
            line-height: 30px;
            `}
    }
    a {
        color: white;
    }
`

const PopupClosingTag = styled.div`
    color: white;
    position: absolute;
    top: 0%;
    right: 3%;
    font-size: 2rem;
    cursor: pointer;
        ${media.phone`
            margin-top: 4px;
            font-size: 1.5rem;
        `}
}
`

export default Popup;