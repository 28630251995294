import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../../styles/global'

const BlockText = (props) => {
	const { content } = props;
        
	return (
		<Text
			dangerouslySetInnerHTML={{__html: content}}  
		/>
	)
}


const Text = styled.div`
	&, p {
		font-family: MrsEavesXLSerifOT-Reg;
		font-size: 20px;
		line-height: 26px;
	}

	${media.phone`
		&, p {
			font-size: 15px;
			letter-spacing: -0.39px;
			line-height: 20px;
		}
	`}
`

BlockText.wrapper = css`

`

export default BlockText
