import React, { useState } from 'react'
import { connect } from 'react-redux'
import { navigate, Link } from 'gatsby'
import styled, { css } from 'styled-components'

import { media, useBreakpoint, isClient } from '../../styles/utils'
import { getSettings, fetchSettings, updateSettings } from '../../store/settings'
import { container, bgIcon, padding, hoverState, bgImage } from '../../styles/global'
import { maroon } from '../../styles/colors';
import { slugify } from '../../utils';
import { useLocation } from 'react-use';

const Header = (props) => {
	const isMobile = useBreakpoint('phone')
	const { menuItems, parent } = props.menu;
	const [mobileMenuActive, setMobileMenuActive] = useState(false)
	const location = useLocation();
	const isTablet = useBreakpoint('tablet');

	const toggleMobileMenu = () => {
		setMobileMenuActive(!mobileMenuActive)
	}

	const getBackToLink = () => {
		// Fallback links

		let link = {
			url: '/',
			text: `Pepe's & Trunk Diner`
		}

		// Venue specific

		if (parent) {
			link = {
				url: `/${parent.slug}`,
				text: parent.text ? parent.text : 'Home'
			}
		}

		return link
	}

	const renderBackTo = () => {

		const link = getBackToLink()
		const windowLocation = typeof window !== 'undefined' ? window.location.pathname : '';
		if (windowLocation.match(/(about|menu|functions|reserve|contact|posts|blog)/)) {
			return (
				<BackTo
					to={link.url}
					overSlider={props.overSlider}
				>
					<Arrow />
					<Description
						dangerouslySetInnerHTML={{ __html: link.text }}
					/>
				</BackTo>
			)
		} else {
			return null
		}
	};

	const getSubMenuItems = (menuItem) => {
		const pageData = JSON.parse(menuItem.acf_json);
		if (!pageData.sections) return;

		return pageData.sections.map((item, i) => {
			const isActive = isClient() && location.hash.includes(slugify(item.title))
			return (
				<MenuItem
					className={isActive && 'active'}
					key={i}
					to={`/pepes/${menuItem.slug.replace('-2', '')}#${slugify(item.title)}`}
				>
					<Heading>
						{item.title}
					</Heading>
				</MenuItem>
			)
		})
	}

	const renderHoverMenu = (menuItem) => {
		if (!menuItem.acf_json) return;
		const pageData = JSON.parse(menuItem.acf_json);
		if (!pageData.sections || pageData.sections.length <= 1) return;

		return (
			<HoverMenu>
				{getSubMenuItems(menuItem)}
			</HoverMenu>
		)
	}

	const renderSubMenu = (menuItem, active) => {
		return;

	}

	const renderMenuItems = () => {
		const items = menuItems.map((item, i) => {
			const [subMenuActive, setSubMenuActive] = useState(false)
			const link = parent ? `${parent.slug === '/' ? '' : `/${parent.slug}`}/${item.slug.replace('-2', '')}` : `/functions/rooms${item.link}`
			return (
				<MenuItemWrapper
					key={i}
					subMenuActive={subMenuActive}
				>
					<MenuItem
						to={link}
						onClick={() => setSubMenuActive(!subMenuActive) || setMobileMenuActive(false)}
						activeClassName={'active'}
					>
						<Heading>
							{item.title}
						</Heading>
					</MenuItem>
				</MenuItemWrapper>
			)
		})

		// Splice in 'Functions' item on venue navs

		// items.splice(2, 0, (
		// 	<MenuItemWrapper>
		// 		<MenuItem
		// 			to={`/functions/rooms`}
		// 			activeClassName={'active'}
		// 			onClick={() => setMobileMenuActive(false)}
		// 		>
		// 			<Heading>
		// 				Functions
		// 			</Heading>
		// 		</MenuItem>
		// 	</MenuItemWrapper>
		// ))

		if (isTablet) {
			items.splice(3, 0, (
				<MenuItemWrapper>
					<MenuItem
						to={`/pepes/functions#enquire`}
						activeClassName={'active'}
						onClick={() => setMobileMenuActive(false)}
					>
						<Heading>
							Enquires
						</Heading>
					</MenuItem>
				</MenuItemWrapper>
			))
		}

		return items

	}

	const renderMenu = () => {
		return (
			<Menu>{renderMenuItems()}</Menu>
		)
	}

	const renderMobileMenu = () => {
		return (
			<MobileMenu
				active={mobileMenuActive}
			>
				{renderMenuItems()}
			</MobileMenu>
		)
	}

	const renderMobile = () => {
		const link = getBackToLink()

		return (
			<>
				<MobileHeader
					data-scroll-header
				>
					<Hamburger
						active={mobileMenuActive}
						onClick={toggleMobileMenu}
					/>

					<MobileBackTo
						to={link.url}
						dangerouslySetInnerHTML={{ __html: link.text }}
					/>
				</MobileHeader>

				{renderMobileMenu()}
			</>
		)
	}

	const renderDesktop = () => {
		return (
			<Wrapper>
				<Container>
					{renderMenu()}
					{renderBackTo()}
				</Container>
			</Wrapper>
		)
	}

	return (
		<>
			{renderDesktop()}
			{renderMobile()}
		</>
	)
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``
const Info = styled.div``

// Layout (Desktop)

const Wrapper = styled.div`
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0px;
	height: 50px;
	background: ${maroon};
	z-index: 10;

	display: flex;
	justify-content: center;

	${media.tablet`
		display: none;
	`}
`

const Container = styled.div`
	${container}

	margin-left: 50px;
	margin-right: 50px;
`

const MenuItem = styled(Link)`
	text-align: center;
	flex: 1;

	display: flex;
	justify-content: center;

	${Heading} {
		font-size: 19px;
		color: white;
		font-weight: bold;
		text-transform: uppercase;
		border-bottom: 2px solid transparent;
		transform: translateY(2px);

		@media (max-width: 1440px) {
			font-size: 16px;
		}

		@media (max-width: 1210px) {
			font-size: 13px;
		}
	}

	&.active {
		${Heading} {
			border-bottom: 2px solid white;
		}
	}
`

const HoverMenu = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	background: ${maroon};
	bottom: 50px;

	display: flex;
	flex-direction: column;
	padding-bottom: 5px;

	${MenuItem} {
		flex: 0 1 50px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
`


const MenuItemWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding-bottom: 4px;
	flex: 1;
	position: relative;
	cursor: pointer;

	&:first-child {
		flex: 0.5;

		> ${MenuItem} {
			justify-content: flex-start;
		}
	}

	&:last-child {
		flex: 0.5;

		> ${MenuItem} {
			justify-content: flex-end;
		}
	}


	}

	> ${MenuItem} {
		flex: 1;
	}

	/* Hover Menu */

	${HoverMenu} {
		opacity: 0;
		pointer-events: none;
		transition: opacity 0.25s ease;
	}

	&:hover {
		${HoverMenu} {
			opacity: 1;
			pointer-events: all;
		}
	}
`

// Menu

const Menu = styled.div`
	display: flex;
	align-items: stretch;
	flex: 1;

	${media.tablet`
		display: none;
	`}
`

// Back To

const Arrow = styled.div`
	background-image: url(${require('../../assets/icons/header-back.svg')});
	${bgImage}
	width: 30px;
	height: 20px;
	margin-right: 29px;
	z-index: 6;
`

const BackTo = styled(Link)`
	display: flex;
	align-items: center;

	position: fixed;
	top: 47px;
	right: 50px;
	color: black;
	background-color: #ffffff87;
	padding: 10px;
	border-radius: 5px;
	

	${Description} {
		font-weight: bold;
		line-height: 18px;
		text-transform: uppercase;
	}

	/* Over Slider Theme */

	${props => {
		if (props.overSlider) return css`
			${Description} {
				color: white;
			}

			${Arrow} {
				background-image: url(${require('../../assets/icons/header-back-white.svg')});
			}
		`
	}}
`

// Mobile Header

const MobileBackTo = styled(Link)`
	font-size: 12px;
	color: white;
	text-transform: uppercase;
	font-weight: 600;

	br {
		display: none;
	}
`

const Hamburger = styled.div`
	${bgIcon}
	height: 21px;
	width: 23px;
	background-image: url(${require('../../assets/icons/hamburger-open.svg')});

	${props => {
		if (props.open) return css`
			height: 18px;
			width: 18px;
			background-image: url(${require('../../assets/icons/hamburger-close.svg')});
		`
	}}
`

const MobileHeader = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 5;

	height: 45px;
	background: ${maroon};

	display: none;
	align-items: center;
	justify-content: space-between;
	${padding}

	${media.tablet`
		display: flex;
	`}
`

// Mobile Menu

const SubMenu = styled.div`
	display: none;
	flex-direction: column;
	justify-content: center;

	${props => {
		if (props.active) return css`
			display: flex;
		`
	}}
`

const MobileMenu = styled.div`
	position: fixed;
	top: 45px;
	right: 0;
	bottom: 0;
	left: 0;
	background: ${maroon};
	z-index: 3;
	height: calc(100vh - 46px);
	border-top: 1px solid white;

	display: none;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	${media.tablet`
		display: flex;
	`}

	${MenuItemWrapper} {
		flex: 0 1 auto;
		margin-bottom: 7px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	${MenuItem} {
		${Heading} {
			font-family: 'learning-curve';
			font-size: 32px;
			line-height: 30px;
			align-self: center;
			font-weight: 600;
			text-transform: inherit;
		}
	}

	/* Animation */

	transition: all 0.45s ease;
	transform: translateY(-100%);
	opacity: 1;
	pointer-events: none;

	${props => {
		if (props.active) return css`
			transform: translateY(0);
			opacity: 1;
			pointer-events: all;
		`
	}}
`


export default Header
