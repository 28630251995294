import React, {Component} from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'

import { media } from '../../styles/utils'
import { lightGrey, black } from '../../styles/colors'
import { bgIcon, bgImage } from '../../styles/global'

const Select = (props) => {
    const { value, options, onChange, validator } = props;

    const renderOptions = () => {
        if (!options) return;

        return options.map((option, i) => {
            return (
                <option 
                    key={i}
                    value={option.value}
                    disabled={option.disabled}
                >
                    {option.value}
                </option>
            )
        })
    }

    return (
        <Wrapper>
            <SelectInput
                value={value}
                onChange={(event) => onChange(event.target.value)}
            >
                {renderOptions()}
            </SelectInput>
        </Wrapper>
    )
}


export const Wrapper = styled.div`
    position: relative;
    width: 100%;
    margin-left: auto;
    user-select: none;

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        background-image: url(${require('../../assets/icons/select-arrow.svg')});
        width: 15px;
        height: 23px;
        ${bgIcon};
        pointer-events: none;

        ${media.phone`
            width: 9px;
            height: 14px;
        `}
    }
`

const SelectInput = styled.select``

export default Select;