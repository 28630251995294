import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../../styles/global'

const BlockPDFLinks = (props) => {
	const { content } = props;
	if (!content) return;
	
	const links = content.map((item, i) => {
		return (
			<Link
				href={item.file}
				target={'_blank'}
			>
				<Arrow/>
				{item.title}
			</Link>
		)
	})
        
	return (
		<Links>
			{links}
		</Links>
	)
}


const Links = styled.div``

const Arrow = styled.div`
	background-image: url(${require('../../../assets/icons/pdf-link-arrow.svg')});	
	${bgImage};
	width: 22px;
	height: 14px;
	margin-right: 27px;

	${media.phone`
		width: 13px;
		height: 8px;
		margin-right: 16px;
	`}
`

const Link = styled.a`
	display: flex;
	align-items: center;
	font-family: MrsEavesXLSerifOT-Reg;
	font-size: 20px;
	line-height: 26px;
	${hoverState}

	&:not(:last-child) {
		margin-bottom: 17px;
	}

	${media.phone`
		font-size: 15px;
		letter-spacing: -0.39px;
		line-height: 20px;

		&:not(:last-child) {
			margin-bottom: 10px;
		}

	`}
`

BlockPDFLinks.wrapper = css`
	${media.phone`
		margin-top: 29px;
	`}
`

export default BlockPDFLinks
// 