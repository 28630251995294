import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../../styles/global'

const BlockHeading = (props) => {
	const { content } = props;
        
	return (
		<Heading
			dangerouslySetInnerHTML={{__html: content}}  
		/>
	)
}


const Heading = styled.div`
	font-size: 22px;
	font-weight: bold;
	text-transform: uppercase;

	${media.phone`
		font-size: 19px;
	`}
`

BlockHeading.wrapper = css`

`

export default BlockHeading
