import { 
    BlockText,
    BlockSlider,
    BlockHeading,
    BlockPDFLinks
} from './templates'

export const resolveBlock = (layout) => {
    let block;

    // Map layout strings to blocks

    const blocks = {
        'text': BlockText,
        'slider': BlockSlider,
        'heading': BlockHeading,
        'pdf_links': BlockPDFLinks
    }
    
    return blocks[layout]
}


